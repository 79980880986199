import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { AuthorizationsComponent } from './components/authorizations/authorizations.component';
import { CompanyComponent } from './components/company/company.component';
import { Error404Component } from './components/error404/error404.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ServicesComponent } from './components/services/services.component';
import { WorkWithUsComponent } from './components/work-with-us/work-with-us.component';

const routes: Routes = [
    { path: '', component: HomeSevenComponent },
    { path: 'contacts', component: ContactsComponent },
    { path: 'company', component: CompanyComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'faqs', component: FaqsComponent },
    { path: 'authorizations', component: AuthorizationsComponent },
    { path: 'work-with-us', component: WorkWithUsComponent},
    // { path: 'home-two', component: HomeTwoComponent },
    // { path: 'home-four', component: HomeFourComponent },
    // { path: 'home-eight', component: HomeEightComponent },
    {
        path: '**',
        pathMatch: 'full',
        component: Error404Component
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
