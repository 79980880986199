import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-home-seven',
  templateUrl: './home-seven.component.html',
  styleUrls: ['./home-seven.component.scss']
})
export class HomeSevenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  @ViewChild('approfondisci') myDiv: ElementRef;
  scrollToElement()
  {
    this.myDiv.nativeElement.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
  }
}
