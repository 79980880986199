import { Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-image-modal',
  templateUrl: './imagemodal.component.html',
  styleUrls: ['./imagemodal.component.css']
})
export class ImageModalComponent {
  @Input() imageSrc = '';
  @Input() imageCaption = '';
  @Input() displayModal = 'none';
  @Input() imgContentClass = '';
  @Output() closeEvent = new EventEmitter<void>();
  
  close(event) {
    //Non chiudere con click su immagine
    if(event?.target?.className === "zoom-modal-content") {
      return;
    }
    this.imgContentClass += " out";
    this.closeEvent.emit();
  }
}