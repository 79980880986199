<div class="main-top-header">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-7">
                <ul class="top-header-information">
                    <li>
                        <span>Tel: </span>
                        <a href="tel:4234543123678">+39 0577 935790</a>
                    </li>
                    <li>
                        <span>Email: </span>
                        <a href="mailto:iromsrl@gmail.com">iromsrl@gmail.com</a>
                    </li>
                    <li>
                        <span>PEC: </span>
                        <a href="mailto:iromsrl@pec.it">iromsrl@pec.it</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="main-navbar">
    <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container-fluid">
            <div class="logo">
                <a routerLink="/">
                    <img
                        src="assets/img/irom-logo.webp"
                        class="black-logo"
                        alt="Irom srl"
                    />
                </a>
            </div>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a routerLink="/" class="nav-link"> Home </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/company" class="nav-link">
                            Chi siamo
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/services" class="nav-link"> Servizi </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/authorizations" class="nav-link">
                            Autorizzazioni
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/faqs" class="nav-link">
                            Domande frequenti
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/contacts" class="nav-link">
                            Contatti
                        </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/work-with-us" class="nav-link">
                            Lavora con noi
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
