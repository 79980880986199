<div id="contact" class="let-contact-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="let-contact-form">
                    <span class="sub-title">Info di contatto</span>
                    <h3>Contattaci</h3>
                    <div class="container py-3 details">
                        <div class="row">
                            <div class="col">
                                Il nostro personale rimane a completa
                                disposizione.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                Per informazioni o richieste di preventivo si
                                prega di inviare un messaggio al seguente
                                indirizzo:
                                <a
                                    class="contact-mail-color"
                                    href="mailto:iromsrl@gmail.com"
                                    >iromsrl@gmail.com</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="container py-3 company-details">
                        <div class="row">
                            <div class="col">Sede Legale:</div>
                        </div>
                        <div class="row">
                            <div class="col">Irom srl</div>
                        </div>
                        <div class="row">
                            <div class="col">Via Campania, 10 Loc. Fosci</div>
                        </div>
                        <div class="row">
                            <div class="col">CAP 53036 - Poggibonsi (SI)</div>
                        </div>
                        <div class="row">
                            <div class="col">Tel. +39 0577 935790</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="let-contact-map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2895.5133148651375!2d11.122128076577017!3d43.47075686421109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132a3a2ee23055cd%3A0x361277039b8b5290!2sIrom%20Srl!5e0!3m2!1sen!2sit!4v1684665603323!5m2!1sen!2sit"
                     style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
