import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent {
  @ViewChild('approfondisci') myDiv: ElementRef;
  scrollToElement()
  {
    this.myDiv.nativeElement.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
  }
}