<div class="three-banner-area irom-company-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span class="sub-title">Autorizzazioni</span>

                            <h3>Certificati per operare nel pieno rispetto
                                dell'ambiente.</h3>
                            <p>Lavoriamo scarti ferrosi e metalli donando loro nuova destinazione nel pieno rispetto delle normative vigenti e dell'ambiente</p>
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" (click)="scrollToElement()">Approfondisci<i class='bx bx-right-arrow-alt'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="four-expect-area pt-100 pb-70" #approfondisci id="approfondisci">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title"># Info autorizzazioni</span>
            <h3>
                Irom Srl è certificata nel recupero, trasporto e smaltimento di
                materiali ferrosi e metalli. Per il trasporto ci avvaliamo dell'azienda Valdelsa Ricicla Srl, di cui alleghiamo autorizzazioni.
            </h3>
            <h4>
                Trasparenza e professionalità ci contraddistinguono da sempre.
            </h4>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Categoria 1 - Valdelsa Ricicla srl - Prot. n.14506/2024 del 10/04/2024</h3>
                    <a href="../../../assets/authorizations/Categoria-1-ValDelsa.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Categoria 1 e 8 - Irom srl - Prot. n.16566/2024 del 19/04/2024</h3>
                    <a href="../../../assets/authorizations/Categoria-1-e-8-Irom.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Categoria 4 - Valdelsa Ricicla srl - Prot. n.14505/2024 del 10/04/2024</h3>
                    <a href="../../../assets/authorizations/Categoria-4-ValDelsa.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Categoria 8 - Irom srl - Prot. n.39792/2021 del 09/09/2021</h3>
                    <a href="../../../assets/authorizations/Categoria-8-Irom.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Categoria 8 - Valdelsa Ricicla srl - Prot. n.55327/2022 del 28/10/2022</h3>
                    <a href="../../../assets/authorizations/Categoria-8-Valdelsa-Ricicla.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Certificato 333</h3>
                    <a href="../../../assets/authorizations/certificato-333.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Certificato 715</h3>
                    <a href="../../../assets/authorizations/certificato-715.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Trasmissione Decreto n.11548 - Irom srl</h3>
                    <a href="../../../assets/authorizations/TRASMISSIONE-DECRETO-N011548-ditta-IROM-S.R.L_.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Decreto n.11548</h3>
                    <a href="../../../assets/authorizations/Decreto_20190711-11548.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Accettazione formale garanzia finanziaria - Irom srl</h3>
                    <a href="../../../assets/authorizations/Irom-srl_accettaz-formale-garanzia-finanziaria.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="expect-item">
                    <div class="expect-icon">
                        <i class="bx bxs-file-pdf"></i>
                    </div>
                    <h3>Decreto n.11548 - Allegato 01</h3>
                    <a href="../../../assets/authorizations/Decreto_n.11548_del_11-07-2019-Allegato-01.pdf" target="_blank"
                        >Approfondisci <i class="bx bx-right-arrow-alt"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</div>
