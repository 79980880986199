import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { AuthorizationsComponent } from './components/authorizations/authorizations.component';
import { CompanyComponent } from './components/company/company.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { Error404Component } from './components/error404/error404.component';
import { AppRoutingModule } from './app-routing.module';
import { ServicesComponent } from './components/services/services.component';
import { WorkWithUsComponent } from './components/work-with-us/work-with-us.component';
import { ImageModalComponent } from './components/imagemodal/imagemodal.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomeSevenComponent,
    HeaderComponent,
    FooterComponent,
    ContactsComponent,
    AuthorizationsComponent,
    CompanyComponent,
    FaqsComponent,
    Error404Component,
    ServicesComponent,
    WorkWithUsComponent,
    ImageModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
