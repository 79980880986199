<div class="three-banner-area irom-company-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                Benvenuto su Irom srl
                            </span>
                            <h3>Tecnologie all'avanguardia per un servizio dinamico ed efficiente.</h3>
                            <p>Lavoriamo scarti ferrosi e metalli donando loro nuova destinazione nel pieno rispetto delle normative vigenti e dell'ambiente</p>
                            <!-- <div class="banner-popup align-items-center">
                                <a
                                    href="https://www.youtube.com"
                                    class="video-btn popup-youtube"
                                    ><i class="bx bx-play"></i>
                                    <span>Intro azienda</span></a
                                >
                            </div> -->
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" (click)="scrollToElement()">Approfondisci<i class='bx bx-right-arrow-alt'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="irom-spacer" #approfondisci id="approfondisci">
<div class="four-record-area pb-70">
    <div class="container-fluid p-0">
        <div class="row m-0 ">
            <div class="d-flex justify-content-center col-lg-6">
                <div class="record-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title">Irom srl: Affidabilità e qualità</span>
                        <h2>Ritiro e raccolta rottami ferrosi e metalli</h2>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="record-item" style="color: black !important;">
                                <p>La nostra azienda è sinonimo di affidabilità, competenza e professionalità.</p>
                                <p>Grazie all'impiego di tecnologie avanzate e all'esperienza pluriennale, Irom Srl è in grado di offrire i propri servizi in tutta Italia. Ci occupiamo del recupero, stoccaggio e commercio di materiali ferrosi e metallici. Dotati di tutte le certificazioni necessarie il nostro personale qualificato opera in conformità alle normative vigenti e nel pieno rispetto dell'ambiente.</p>
                            </div>
                        </div>
                    </div>
                    <a class="cmn-banner-btn" routerLink="/services">Scopri i nostri servizi<i class='bx bx-right-arrow-alt'></i></a>
                </div>
            </div>
            <div class="d-flex justify-content-center col-lg-6">
                <div class="record-content">
                    <div class="one-section-title three-section-title">
                        <span class="sub-title"># I nostri numeri</span>
                        <h2>Traguardi raggiunti</h2>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <div class="record-item">
                                <h3>40+</h3>
                                <span>Anni di esperienza</span>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="record-item">
                                <h3>100+</h3>
                                <span>Clienti soddisfatti</span>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="record-item">
                                <h3>200+</h3>
                                <span>Fornitori soddisfatti</span>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <div class="record-item">
                                <h3>5000+</h3>
                                <span>Tonellate di materiale smaltite / anno</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
