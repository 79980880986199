import { Component, ElementRef, ViewChild } from '@angular/core';
import { ImageModalComponent } from '../imagemodal/imagemodal.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent {
  @ViewChild('approfondisci') myDiv: ElementRef;
  @ViewChild(ImageModalComponent) imageModal: ImageModalComponent;
  selectedImageSrc = '';
  selectedImageCaption = '';
  displayModal = 'none';


  scrollToElement()
  {
    this.myDiv.nativeElement.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
  }

  openModal(imageSrc: string, imageCaption: string) {
    this.imageModal.imgContentClass = "zoom-modal-content";
    this.selectedImageSrc = imageSrc;
    this.selectedImageCaption = imageCaption;
    this.displayModal = 'block';
  }

  closeModal() {
    //Timeout to let the zoom-out css animation do its work
    setTimeout(() => {
      this.displayModal = 'none';
    }, 400);
  }
}
