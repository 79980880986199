<div class="three-banner-area irom-company-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                I Nostri Servizi
                            </span>
                            <h3>Recupero, stoccaggio e commercio di materiali ferrosi e metalli e non</h3>
                            <p>Lavoriamo scarti ferrosi e metalli donando loro nuova destinazione nel pieno rispetto
                                delle normative vigenti e dell'ambiente.</p>
                            <!-- <div class="banner-popup align-items-center">
                                <a
                                    href="https://www.youtube.com/watch?v=Y5KCDWi7h9o"
                                    class="video-btn popup-youtube"
                                    ><i class="bx bx-play"></i>
                                    <span>Intro azienda</span></a
                                >
                            </div> -->
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" (click)="scrollToElement()">Approfondisci<i
                                            class='bx bx-right-arrow-alt'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="three-loan-area four-loan-area pt-100 pb-70">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title">#UnMondoMigliore</span>
        </div>

        <div class="sorting-menu">
            <ul>
                <li class="filter active" data-filter="all"><i class='bx bx-bullseye'></i> I NOSTRI SERVIZI</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Ritiro</span>
                    <h3>Ritiro materiali ferrosi e metallici</h3>
                    <p>Per facilitare la raccolta effettuiamo il ritiro dei materiali ferrosi e non, direttamente presso
                        la sede del fornitore secondo programmazione periodica.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Controllo</span>
                    <h3>Controllo radioattività</h3>
                    <p>Grazie alle nostre apparecchiature avanzate i rottami ferrosi e i metalli vengono sottoposti ad
                        un accurato controllo per verificare la presenza di radioattività.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Stoccaggio</span>
                    <h3>Stoccaggio e cernita</h3>
                    <p>Presso la sede di Irom i materiali ferrosi e metallici ritirati dai fornitori vengono stoccati;
                        successivamente viene applicata una cernita, ovvero una lavorazione, determinando così un
                        prodotto pronto per essere recuperato o smaltito.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Rivendita</span>
                    <h3>Intermediazione rifiuti pericolosi</h3>
                    <p>La nostra azienda si occupa di effettuare intermediazione dei rifiuti pericolosi tramite altre
                        aziende specializzate nel settore.</p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web">
                <div class="loan-item">
                    <span>Noleggio</span>
                    <h3>Noleggio containers</h3>
                    <p>Inoltre, siamo disponibili per noleggio compattatori e containers, scarrabili e non scarrabili,
                        di varie metrature per i rifiuti e in comodato gratuito per i rottami ferrosi e non.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="five-counter-area pb-70">
    <div class="container text-center p-5">
        <h1>MATERIALI TRATTATI</h1>
        <div class="row mt-5">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Metalli ferrosi</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item counter-two">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Metalli non ferrosi</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Rame e sue leghe</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item counter-two">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Legno</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Plastica</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item counter-two">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Vetro</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Apparecchiature fuori uso</span>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="counter-item counter-two">
                    <h3>
                        <div class="icon">
                            <i class='bx bx-check'></i>
                        </div>
                    </h3>
                    <span>Intermediazione CER pericolosi</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="blog" class="blog-style-area pt-100 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/8.Carta2.webp" alt="Irom srl - Riciclo carta"
                            (click)="openModal('assets/img/8.Carta2.webp', 'Irom srl - Riciclo carta')">
                        <div class="tag">Riciclo carta</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/4.Camion.webp" alt="Irom srl - Camion al lavoro"
                            (click)="openModal('assets/img/4.Camion.webp', 'Irom srl - Camion al lavoro')">
                        <div class="tag">Trasporto</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/2.Aluminio.webp" alt="Irom srl - Lavorazione aluminio"
                            (click)="openModal('assets/img/2.Aluminio.webp', 'Irom srl - Lavorazione aluminio')">
                        <div class="tag">Aluminio</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/3.Container.webp" alt="Irom srl - Container"
                            (click)="openModal('assets/img/3.Container.webp', 'Irom srl - Container')">
                        <div class="tag">Container</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/6.Ragno.webp" alt="Irom srl - Ragno al lavoro"
                            (click)="openModal('assets/img/6.Ragno.webp', 'Irom srl - Ragno al lavoro')">
                        <div class="tag">Ragno</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/5.Acciaio.webp" alt="Irom srl - Acciaio Inox"
                            (click)="openModal('assets/img/5.Acciaio.webp', 'Irom srl - Acciaio Inox')">
                        <div class="tag">Acciaio inox</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/10.Camion2.webp" alt="Irom srl - Camion al lavoro"
                            (click)="openModal('assets/img/10.Camion2.webp', 'Irom srl - Camion al lavoro')">
                        <div class="tag">Camion</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/13.SdruccioleFerro.webp" alt="Irom srl - Sdrucciole ferrose"
                            (click)="openModal('assets/img/13.SdruccioleFerro.webp', 'Irom srl - Sdrucciole ferrose')">
                        <div class="tag">Sdrucciole ferrose</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/14.LegaRame.webp" alt="Irom srl - Lavorazione lega rame"
                            (click)="openModal('assets/img/14.LegaRame.webp', 'Irom srl - Lavorazione lega rame')">
                        <div class="tag">Lega rame</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/15.Pneumatici.webp" alt="Irom srl - Pneumatici"
                            (click)="openModal('assets/img/15.Pneumatici.webp', 'Irom srl - Pneumatici')">
                        <div class="tag">Pneumatici</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/16.Dispositivi-da-smaltire.webp" alt="Irom srl - Dispositivi da smaltire"
                            (click)="openModal('assets/img/16.Dispositivi-da-smaltire.webp', 'Irom srl - Dispositivi da smaltire')">
                        <div class="tag">Dispositivi da smaltire</div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="blog-style-left-card">
                    <div class="blog-image">
                        <img src="assets/img/17.Rame.webp" alt="Irom srl - Rame"
                            (click)="openModal('assets/img/17.Rame.webp', 'Irom srl - Rame')">
                        <div class="tag">Rame</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-image-modal
[imageSrc]="selectedImageSrc"
[imageCaption]="selectedImageCaption"
[displayModal]="displayModal"
(closeEvent)="closeModal()">
</app-image-modal>
