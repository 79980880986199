<div class="need-help-faq-area pb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="need-help-faq-content">
                    <span class="sub-title">FAQ</span>
                    <h3>Leggi le domande più frequenti</h3>
                    <div class="need-help-faq-accordion">
                        <div class="accordion" id="FaqAccordion">
                            <div class="accordion-item">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Qual è l'attività principale di IROM SRL?
                                </button>
                                <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#FaqAccordion">
                                    <div class="accordion-body">
                                        <p>IROM SRL opera principalmente nella raccolta e nel riciclaggio di materiali ferrosi, metallici e rifiuti in generale.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Come si è sviluppata l'azienda nel corso degli anni?
                                </button>
                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                    <div class="accordion-body">
                                        <p>L'azienda è stata fondata negli anni '80 da Enzo Mori e suo figlio Mauro. Negli anni '90, si è unita la moglie di Mauro, Giovanna, seguita dal loro figlio Giacomo e la sua compagna</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Quali certificazioni e autorizzazioni detiene IROM SRL?
                                </button>
                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                    <div class="accordion-body">
                                        <p>IROM SRL detiene l'ATTO n° 11548 datato 11/07/2019, il REGOLAMENTO (UE) N° 333/2011, il REGOLAMENTO (UE) N° 715/2013, ed è registrata nell'ALBO CAT. 8 INTERMEDIAZIONE e ALBO CAT 1; 4.</p>
                                    </div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Come gestisce IROM SRL il trasporto e le attrezzature per il trattamento dei rifiuti?
                                </button>
                                <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#FaqAccordion">
                                    <div class="accordion-body">
                                        <p>IROM SRL dispone di varie attrezzature per il trattamento dei rifiuti. Per il trasporto interno ed esterno, utilizzano i servizi di VALDELSA RICICLA SRL. Per quantità maggiori, utilizzano anche altri trasportatori esterni. Hanno attrezzature all'interno dei loro locali per la riduzione volumetrica di rottami o rifiuti e caricatrici meccaniche per gli stessi. L'azienda fornisce cassoni di varie dimensioni, compattatori o cassoni scarrabili al luogo del produttore.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="need-help-faq-image">
                    <img src="assets/img/iromsrl-header.webp" alt="image">

                    <div class="help-box">
                        <span>Domande & risposte</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
