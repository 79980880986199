import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-authorizations',
  templateUrl: './authorizations.component.html',
  styleUrls: ['./authorizations.component.scss']
})
export class AuthorizationsComponent {
    @ViewChild('approfondisci') myDiv: ElementRef;
    scrollToElement()
    {
      this.myDiv.nativeElement.scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
    }
}

