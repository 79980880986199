<div id="error404" class="error-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12 text-center">
                <div class="error-content">
                    <a href="/">
                        <img src="../../../assets/resources//error-404.png" alt="Pagina non trovata" style="width: 100%; max-width: 800px;"/>
                    </a>
                    <h3>Pagina Non Trovata</h3>
                    <div class="container py-3 details">
                        <div class="row">
                            <div class="col">
                                Siamo spiacenti, la pagina che stai cercando non esiste o è stata spostata.
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                Potrebbe essere utile tornare alla nostra
                                <a class="contact-mail-color" href="/">Home Page</a> o contattarci direttamente all'indirizzo:
                                <a class="contact-mail-color" href="mailto:iromsrl@gmail.com">iromsrl@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
