<div id="contact">
    <div class="container-fluid">
        <div class="row ">
            <div class="col-lg-12 col-md-12">
                <div class="container py-3 details">
                    <span class="sub-title">Opportunità di lavoro</span>
                    <h3>Vorresti lavorare con noi?</h3>
                    <div class="container py-3 details">
                        <div class="row">
                            <div class="col">
                                Irom srl è leader nel settore del riciclo di
                                materiali ferrosi, plastica, legno e altro
                                ancora. Ci impegniamo per l'ambiente e per un
                                futuro più sostenibile. La nostra missione non
                                sarebbe possibile senza il talento e la passione
                                del nostro team. Siamo alla ricerca di individui
                                dinamici e motivati per unirsi a noi e
                                contribuire al nostro successo.
                            </div>
                        </div>
                    </div>
                    <div class="container py-3 details">
                        <h4>Posizioni aperte</h4>
                        <div class="row">
                            <div class="col">
                                <h5>1. Magazziniere</h5>
                                Il nostro team sta cercando un magazziniere
                                esperto per gestire tutte le attività quotidiane
                                del magazzino. Il candidato ideale ha una forte
                                etica del lavoro, attenzione al dettaglio e
                                abilità nell'organizzazione. L'esperienza nel
                                settore del riciclo è un vantaggio, ma non
                                essenziale.
                                <br/>
                                <b>Requisiti:</b>
                                <ul>
                                    <li>
                                        Esperienza di lavoro in un magazzino o
                                        in un ruolo simile
                                    </li>
                                    <li>Capacità di lavorare in team</li>
                                    <li>Flessibilità oraria</li>
                                    <li>
                                        Conoscenza delle normative di sicurezza
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <h5>2. Autista</h5>
                                Cerchiamo autisti esperti per garantire la
                                consegna e il ritiro sicuro e tempestivo dei
                                materiali. I candidati devono avere una patente
                                di guida valida, un buon record di guida e la
                                capacità di lavorare in modo indipendente.
                                <br/>
                                <b>Requisiti:</b>
                                <ul>
                                    <li>
                                        Esperienza come autista professionale
                                    </li>
                                    <li>
                                        Capacità di gestire programmi di
                                        consegna flessibili
                                    </li>
                                    <li>
                                        Esperienza nel settore del riciclo è un
                                        plus, ma non essenziale
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            Se sei interessato per una di queste posizioni, ci piacerebbe sentirti.
                            Invia il tuo curriculum all'indirizzo email <a class="contact-mail-color" href="mailto:iromsrl@gmail.com">iromsrl@gmail.com</a>. <br/>
                            La nostra azienda è un datore di lavoro che promuove le pari opportunità e la diversità. Tutti i candidati qualificati riceveranno considerazione per l'impiego senza distinzione di razza, colore, religione, sesso, orientamento sessuale, identità di genere, origine nazionale, disabilità o qualsiasi altra caratteristica protetta dalla legge.
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
