<footer class="footer-style-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-3 col-lg-3 px-5">
                <div class="footer-logo">
                    <a routerLink="/">
                        <img
                            src="assets/img/irom-logo-noBg-white.png"
                            alt="Irom srl"
                        />
                    </a>
                </div>
                <div class="single-footer-widget">
                    <p class="footer-logo-desc">
                        COMMERCIO ROTTAMI FERROSI E METALLICI. CENTRO
                        AUTORIZZATO AL RECUPERO DI RIFIUTI SPECIALI E NON.
                        INTERMEDIAZIONE RIFIUTI PERICOLOSI
                    </p>
                </div>
            </div>
            <div class="col-sm-3 col-lg-3 px-5 footer-quick">
                <div class="single-footer-widget">
                    <h4>Irom srl</h4>
                    <ul class="address-info">
                        <li>
                            <i class="bx bx-phone-call"></i>
                            <a href="tel:+390577935790">+39 0577 935790</a>
                        </li>
                        <li>
                            <i class="bx bx-message-square-detail"></i>
                            <a href="mailto:iromsrl@gmail.com"
                                >iromsrl@gmail.com</a
                            >
                        </li>
                        <li>
                            <i class="bx bx-message-dots"></i>
                            <a href="mailto:iromsrl@pec.it">iromsrl@pec.it</a>
                        </li>
                        <li>
                            <i class="bx bx-current-location"></i>
                            <p>
                                Via Campania, 10 Loc. Fosci 53036 Poggibonsi
                                (SI)
                            </p>
                        </li>
                        <li>
                            <i class="bx bxs-building-house"></i>
                            <p>P.Iva 00720690528</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-3 col-lg-3 px-5 footer-quick">
                <div class="single-footer-widget ps-3">
                    <h4>Navigazione</h4>
                    <ul class="quick-link">
                        <li>
                            <a routerLink="/">Home</a>
                        </li>
                        <li>
                            <a routerLink="/company">Chi siamo</a>
                        </li>
                        <li>
                            <a routerLink="/services">Servizi</a>
                        </li>
                        <li>
                            <a routerLink="/authorizations">Autorizzazioni</a>
                        </li>
                        <li>
                            <a routerLink="/faqs">Domande frequenti</a>
                        </li>
                        <li>
                            <a routerLink="/contacts">Contatti</a>
                        </li>
                        <li>
                            <a routerLink="/work-with-us">Lavora con noi</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-3 col-lg-3 px-5 footer-quick">
                <div class="single-footer-widget ps-3">
                    <h4>Privacy, Cookie Policy, Termini e condizioni</h4>
                    <ul class="quick-link">
                        <li>
                            <a
                                href="https://www.iubenda.com/privacy-policy/89034237"
                                class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
                                title="Privacy Policy "
                                >Privacy Policy</a
                            >
                            <script type="text/javascript">
                                (function (w, d) {
                                    var loader = function () {
                                        var s = d.createElement("script"),
                                            tag =
                                                d.getElementsByTagName(
                                                    "script"
                                                )[0];
                                        s.src =
                                            "https://cdn.iubenda.com/iubenda.js";
                                        tag.parentNode.insertBefore(s, tag);
                                    };
                                    if (w.addEventListener) {
                                        w.addEventListener(
                                            "load",
                                            loader,
                                            false
                                        );
                                    } else if (w.attachEvent) {
                                        w.attachEvent("onload", loader);
                                    } else {
                                        w.onload = loader;
                                    }
                                })(window, document);
                            </script>
                        </li>
                        <li>
                            <a
                                href="https://www.iubenda.com/privacy-policy/89034237/cookie-policy"
                                class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
                                title="Cookie Policy "
                                >Cookie Policy</a
                            >
                            <script type="text/javascript">
                                (function (w, d) {
                                    var loader = function () {
                                        var s = d.createElement("script"),
                                            tag =
                                                d.getElementsByTagName(
                                                    "script"
                                                )[0];
                                        s.src =
                                            "https://cdn.iubenda.com/iubenda.js";
                                        tag.parentNode.insertBefore(s, tag);
                                    };
                                    if (w.addEventListener) {
                                        w.addEventListener(
                                            "load",
                                            loader,
                                            false
                                        );
                                    } else if (w.attachEvent) {
                                        w.attachEvent("onload", loader);
                                    } else {
                                        w.onload = loader;
                                    }
                                })(window, document);
                            </script>
                        </li>
                        <li>
                            <a
                                href="https://www.iubenda.com/termini-e-condizioni/89034237"
                                class="iubenda-black iubenda-noiframe iubenda-embed iubenda-noiframe"
                                title="Termini e Condizioni "
                                >Termini e Condizioni</a
                            >
                            <script type="text/javascript">
                                (function (w, d) {
                                    var loader = function () {
                                        var s = d.createElement("script"),
                                            tag =
                                                d.getElementsByTagName(
                                                    "script"
                                                )[0];
                                        s.src =
                                            "https://cdn.iubenda.com/iubenda.js";
                                        tag.parentNode.insertBefore(s, tag);
                                    };
                                    if (w.addEventListener) {
                                        w.addEventListener(
                                            "load",
                                            loader,
                                            false
                                        );
                                    } else if (w.attachEvent) {
                                        w.attachEvent("onload", loader);
                                    } else {
                                        w.onload = loader;
                                    }
                                })(window, document);
                            </script>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
