<div class="three-banner-area irom-company-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="banner-content">
                            <span>
                                Irom srl
                            </span>
                            <h3>Servizio di gestione e riciclaggio di metalli e materiali ferrosi</h3>
                            <!-- <div class="banner-popup align-items-center">
                                <a
                                    href="https://www.youtube.com"
                                    class="video-btn popup-youtube"
                                    ><i class="bx bx-play"></i>
                                    <span>Intro</span></a
                                >
                            </div> -->
                            <ul>
                                <li>
                                    <a class="cmn-banner-btn" (click)="scrollToElement()">Approfondisci<i class='bx bx-right-arrow-alt'></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="about" class="about-style-area ptb-100" #approfondisci id="approfondisci">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-style-image">
                    <img src="assets/img/6.Ragno.webp" alt="Irom srl - Area operativa, due ragni al lavoro">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-style-content">
                    <span class="sub-title">La nostra azienda</span>
                    <h3>Cosa offriamo?</h3>
                    <p class="bold">Nell'epoca in cui viviamo promuovere la cultura del riciclaggio è un punto fondamentale
                        soprattutto per la sopravvivenza e per l'ambiente .</p>
                    <p>Quotidianamente ci impegniamo con serietà, affidabilità costante e grande passione per il
                        lavoro al processo di produzione , trattamento e riciclo di materiali ferrosi , metallici e rifiuti in
                        genere.</p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="counter-box">
                                <h4>40+</h4>
                                <span>Anni di esperienza</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="counter-box">
                                <h4>200+</h4>
                                <span>Fornitori soddisfatti</span>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="counter-box">
                                <h4>100+</h4>
                                <span>Clienti soddisfatti</span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="counter-box">
                                <h4>3500+</h4>
                                <span>Mq azienda</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="services" class="core-service-area ptb-100">
    <div class="container container-services">
        <div class="main-section-title">
            <span class="sub-title"></span>
            <h2>I nostri servizi</h2>
        </div>

        <div class="core-service-list-tab">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="core-service-1-tab" data-bs-toggle="tab" href="#core-service-1" role="tab" aria-controls="core-service-1">Ritiro materiali ferrosi</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="core-service-2-tab" data-bs-toggle="tab" href="#core-service-2" role="tab" aria-controls="core-service-2">Controllo radioattività</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="core-service-3-tab" data-bs-toggle="tab" href="#core-service-3" role="tab" aria-controls="core-service-3">Stoccaggio e cernita</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="core-service-4-tab" data-bs-toggle="tab" href="#core-service-4" role="tab" aria-controls="core-service-4">Intermediazione rifiuti pericolosi</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="core-service-5-tab" data-bs-toggle="tab" href="#core-service-5" role="tab" aria-controls="core-service-5">Noleggio containers</a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="core-service-1" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-check-circle"></i>
                                </div>
                                <h3>Ritiro materiali ferrosi e metalli</h3>
                                <p>Per facilitare la raccolta effettuiamo il ritiro dei materiali ferrosi e non, direttamente presso la sede del fornitore secondo programmazione periodica.</p>
                                <ul class="list">
                                    <li><i class='bx bx-chevrons-right'></i> Rispetto per l'ambiente</li>
                                    <li><i class='bx bx-chevrons-right'></i> Servizio di eccellenza</li>
                                    <li><i class='bx bx-chevrons-right'></i> Esperienza decennale</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/17.Rame.webp" alt="Irom srl - Lavorazione rame">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-2" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-dollar-circle"></i>
                                </div>
                                <h3>Controllo radioattività metalli</h3>
                                <p>Grazie alle nostre apparecchiature avanzate i rottami ferrosi e i metalli vengono sottoposti ad un accurato controllo per verificare la presenza di radioattività. </p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/2.Aluminio.webp" alt="Irom srl - Lavorazione aluminio">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-3" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-home-circle"></i>
                                </div>
                                <h3>Stoccaggio e cernita</h3>
                                <p>Presso la sede di Irom i materiali ferrosi e metallici ritirati dai fornitori vengono stoccati; successivamente viene applicata una cernita, ovvero una lavorazione, determinando così un prodotto pronto per essere recuperato o smaltito. </p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/5.Acciaio.webp" alt="Irom srl - Lavorazione acciaio">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-4" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-hand-up"></i>
                                </div>
                                <h3>Intermediazione rifiuti pericolosi</h3>
                                <p>La nostra azienda si occupa di effettuare intermediazione dei rifiuti pericolosi tramite altre aziende specializzate nel settore.</p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/13.SdruccioleFerro.webp" alt="Irom srl - Sdruciole ferrose">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade" id="core-service-5" role="tabpanel">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="core-service-content">
                                <div class="icon">
                                    <i class="bx bxs-hand-up"></i>
                                </div>
                                <h3>Noleggio containers</h3>
                                <p>Inoltre, siamo disponibili per noleggio compattatori e containers, scarrabili e non scarrabili, di varie metrature per i rifiuti e in comodato gratuito per i rottami ferrosi e non.</p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="core-service-image">
                                <img src="assets/img/3.Container.webp" alt="Irom srl - Noleggio container">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="buying-home-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-7 col-md-12">
                <div class="buying-home-content">
                    <span class="sub-title">La cultura del riciclaggio</span>
                    <h3>Processi di lavorazione</h3>
                    <p>L'industria dei metalli, come in altri comparti, è tenuta a muoversi in termini di sviluppo
                        sostenibile e a misurarsi con i grandi problemi del nostro tempo, come la salvaguardia dell'ambiente, l'energia, approvvigionamento di materie prime. Disponiamo
                        anche di varie attrezzature per la selezione e la riduzione volumetrica. </p>
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Obiettivo</h4>
                                <p>Rinnovare il mondo che ci circonda attraverso il recupero di materiali di scarto e la loro successiva rivalorizzazione</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Tecniche di raffinamento</h4>
                                <p>L'esperienza maturata nel settore ci ha portato ad una
                                    continua ricerca di affinamento delle tecniche di riciclaggio.
                                    </p>
                            </div>
                        </div>
                    </div>

                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Selezione dei materiali</h4>
                                <p>Il processo produttivo consiste nel selezionare ancor meglio i vari prodotti per poi procedere alla consegna nelle varie Acciaierie e Fonderie, invece il prodotto non EOW  viene consegnato nelle discariche autorizzate.</p>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <div class="buying-inner-box">
                                <div class="icon">
                                    <i class='bx bx-check'></i>
                                </div>
                                <h4>Diversi tipi di materiali</h4>
                                <p>L'Azienda ritira diversi tipi di materiali fra i quali rottami ferrosi ,rottami metallici ,
                                    rifiuti misti in genere come (legno, imballaggi in materiali misti ,plastica, e tanti altri
                                    materiali come dai codici delle nostre Autorizzazioni)
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
